import { StaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Row, Col } from "react-bootstrap"

const changeLink = (wordPressUrl,link) => {
  let newUri = link.replace(wordPressUrl, '');
  return newUri
}
const MENU_QUERY = graphql`

    fragment MenuItem on WPGraphQL_MenuItem {
        id
        label
        url
        title
        target
        childItems{
          nodes{
          label
          url
          }
        }
    }

    query GETFOOTERMENU {
        wpgraphql {
            menuItems(where: {location: FOOTER_MENU}) {
                nodes {
                    ...MenuItem
                }
            }
            generalSettings {
                url
            }
        }
    }
`
const FooterMenu = ({ siteTitle }) => (
  <StaticQuery
  query={MENU_QUERY}
  render={data => {
    const menuItems = data.wpgraphql.menuItems.nodes
    const wordPressUrl = data.wpgraphql.generalSettings.url
    return (
      <ul className="list-unstyled">
        {menuItems.map(item => (
          <li key={changeLink(wordPressUrl,item.url)} style={{ margin: `0 0px` }}>
            <a
              href={changeLink(wordPressUrl,item.url)}
              style={{
                color: `white`,
               
                textDecoration: `none`,
                fontFamily: `sans-serif`,
              }}
            >
              {item.label}
            </a>
          </li>
        ))}
      </ul>
    )}}
    />
  
  )
                
  

FooterMenu.propTypes = {
  siteTitle: PropTypes.string,
}

FooterMenu.defaultProps = {
  siteTitle: ``,
}

export default FooterMenu
