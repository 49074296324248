import { StaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Navbar, NavDropdown, Nav, Button } from "react-bootstrap"

const changeLink = (wordPressUrl,link) => {
  let newUri = link.replace(wordPressUrl, '');
  return newUri
}
const MENU_QUERY = graphql`

    fragment MenuItem on WPGraphQL_MenuItem {
        id
        label
        url
        title
        target
        childItems{
          nodes{
          label
          url
          }
        }
    }

    query GETMAINMENU {
        wpgraphql {
            menuItems(where: {location: HEADER_MENU}) {
                nodes {
                    ...MenuItem
                }
            }
            generalSettings {
                url
            }
        }
    }
`
const Header = ({ siteTitle }) => (
  <StaticQuery
    query={MENU_QUERY}
    render={data => {
      const menuItems = data.wpgraphql.menuItems.nodes
      const wordPressUrl = data.wpgraphql.generalSettings.url

      return  (<header>
        <Navbar bg="ci" expand="lg" variant="dark" fixed="top">
          <Navbar.Brand href="/">{siteTitle}</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              {menuItems.map(item =>
                item.childItems.nodes && item.childItems.nodes.length >0 ? (
                  <NavDropdown
                    title={item.label}
                    key={item.label}
                    style={{ margin: "0 15px" }}
                    id="basic-nav-dropdown"
                  >
                    {item.childItems.nodes.map(childItem => (
                      <NavDropdown.Item key={childItem.label} href={`${changeLink(wordPressUrl,childItem.url)}`}>
                        {childItem.label}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                ) : (
                  <Nav.Link
                  key={item.label}
                    href={changeLink(wordPressUrl,item.url)}
                    style={{ margin: "0 15px" }}
                  >
                    {item.label}
                  </Nav.Link>
                )
              )}
            </Nav>

            <Button variant="light" href="https://nx4994.your-storageshare.de" size="sm" >
              Login
            </Button>
          </Navbar.Collapse>
        </Navbar>
       
      </header>
    )}}
  />

)
              

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
