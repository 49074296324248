/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"
import Header from "./header"
import FooterMenu from "./FooterMenu"
import '@fortawesome/fontawesome-svg-core/styles.css';
import FadeIn from "react-fade-in"
import CookieConsent from "react-cookie-consent"


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome,faMapMarkerAlt,faPhoneAlt,faEnvelope } from "@fortawesome/free-solid-svg-icons"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      logoIvd: file(relativePath: { eq: "ivd_logo.png" }) {
        ...squareImage
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle="Fackelmann Immobilien" />
      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}

      <main>{children}</main>

      <footer className="page-footer font-small text-white bg-ci pt-4">
        <Container fluid className="text-center text-md-left">
          <Row>
            <Col lg={4}>
              <h5 className="text-uppercase">Über uns</h5>
              <p>
                Wir vermitteln landwirtschaftliche Immobilien seit 50 Jahren und
                sind Wohnungsbauunternehmer.
              </p>
            </Col>

            <Col lg={3} className="text-center">
              <h5 className="text-uppercase">Mitglied im</h5>

              <Img
                fixed={data.logoIvd.childImageSharp.fixed}
                style={{ height: "100px" }}
              />
            </Col>

            <Col lg={3} className="">
            <h5 className="text-uppercase">Kontakt</h5>
              <p>
                
                <FontAwesomeIcon icon={faHome} />
                &nbsp;Fackelmann Immobilien OHG
                <br />
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                &nbsp;&nbsp;Dreifaltigkeitsplatz 3 <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;84028 Landshut
                <br />
                <FontAwesomeIcon icon={faPhoneAlt} />
                &nbsp;Tel: 0871/ 28027
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Fax: 0871/ 29698 <br />{" "}
                <FontAwesomeIcon icon={faEnvelope} />
                &nbsp;E-Mail: info@immo-fackelmann.de{" "}
              </p>
            </Col>
            <Col lg={2}>
              <h5 className="text-uppercase">Menü</h5>
              <FooterMenu />
            </Col>
            {/* <hr className="clearfix w-100 d-md-none pb-3" /> */}

            <Col
              xs={12}
              className="footer-copyright text-center bg-ci-dark text-white py-3"
            >
              © 2019 Copyright:
              <a> Fackelmann Immobilien OHG</a>
            </Col>
          </Row>
        </Container>
      </footer>
      <FadeIn transitionDuration="1000">
          <CookieConsent
            contentClasses="cookie-content"
            containerClasses="cookie-container"
            buttonClasses="cookie-button text-white"
            location="bottom"
            buttonText="Cookies erlauben"
            declineButtonText="Cookies Ablehnen"
            cookieName="Cookie"
            expires={20}
            enableDeclineButton
            onDecline={() => {
            console.log("cookies deactivated")
            }}          >
            Wir benutzen Cookies um Ihre Benutzer Erfahrung zu verbessern.{" "}
            <a
              href="/datenschutz"
              style={{ fontSize: "16px", marginLeft: "1%" }}
            >
              Unsere Datenschutzerklärung
            </a>
          </CookieConsent>
        </FadeIn>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
